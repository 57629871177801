<template>
  <div class="standings-view-container">
    <b-row>
      <b-col>
        <b-card
          v-if="tournament?.status_id === TournamentStatusType.FINISHED || tournament?.status_id === TournamentStatusType.CLOSED"
          style="background-color: #28304547"
          class="mb-0"
        >
          <h5 class="m-0 mb-2">
            Final results
          </h5>

          <b-row>
            <b-col
              sm="12"
              md="4"
            >
              <b-card>
                <span>
                  <b-img
                    :src="require(`@/assets/images/icons/1stplace.png`)"
                    alt="1st place"
                    height="35"
                  />
                </span>
                <span
                  class="font-weight-bold ml-2 font-medium-2"
                >{{
                    tournament?.rankings[0].participant?.participant?.name || tournament?.rankings[0].participant?.name
                }}</span>
              </b-card>
            </b-col>
            <b-col
              v-if="tournament?.rankings.length >= 2"
              sm="12"
              md="4"
            >
              <b-card>
                <span>
                  <b-img
                    :src="require(`@/assets/images/icons/2place.png`)"
                    alt="2nd place"
                    height="35"
                  />
                </span>
                <span class="font-weight-bold ml-2 font-medium-2">
                  {{
                    tournament?.rankings[0].participant?.participant?.name || tournament?.rankings[1].participant?.name
                  }}
                </span>
              </b-card>
            </b-col>

            <b-col
              v-if="tournament?.rankings.length >= 3"
              sm="12"
              md="4"
            >
              <b-card>
                <span>
                  <b-img
                    :src="require(`@/assets/images/icons/3place.png`)"
                    alt="3rd place"
                    height="35"
                  />
                </span>
                <span class="font-weight-bold ml-2 font-medium-2">
                  {{
                    tournament?.rankings[0].participant?.participant?.name || tournament?.rankings[2].participant?.name
                  }}
                </span>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <h5 class="m-0">
            Standings
          </h5>
        </b-card>
      </b-col>
    </b-row>

    <b-row :class="tournament?.status_id >= TournamentStatusType.FINISHED ? 'scroll-container final-results' : 'scroll-container' ">
      <b-col class="h-100">
        <vue-perfect-scrollbar
          :settings="{suppressScrollX: true,}"
          class="h-100"
        >
          <b-table
            ref="refParticipantsListTable"
            :fields="standingsTableCols"
            :items="standings"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            empty-text="No standings yet"
            primary-key="id"
            show-empty
            class="mb-0 h-100"
            table-class="h-100"
          >
            <template #cell(name)="data">
              {{ participantName(data.item) }}
            </template>

            <template #cell(rank)="data">
              <template v-if="data.index === 0">
                <b-img
                  :src="require(`@/assets/images/icons/1stplace.png`)"
                  alt="1st place"
                  height="20"
                />
              </template>
              <template v-else-if="data.index === 1">
                <b-img
                  :src="require(`@/assets/images/icons/2place.png`)"
                  alt="2nd place"
                  height="20"
                />
              </template>
              <template v-else-if="data.index === 2 && data.item.elapsed_time !== null">
                <b-img
                  :src="require(`@/assets/images/icons/3place.png`)"
                  alt="3rd place"
                  height="20"
                />
              </template>
              <template v-else>
                {{ data.index + 1 }}
              </template>
            </template>

            <template
              #cell(elapsed_time)="data"
            >
              {{ data.item.elapsed_time ? millisecondsToTime(data.item.elapsed_time) : 'no result yet' }}
            </template>

            <template
              #cell(difference)="data"
            >
              <template
                v-if="data.index === 0"
              >
                {{ '--:--:--.--' }}
              </template>
              <template
                v-else
              >
                {{ data.item.difference ? millisecondsToTime(data.item.difference) : '' }}
              </template>

            </template>

            <template
              #cell(gap)="data"
            >
              <template v-if="data.index === 0">
                {{ '--:--:--.--' }}
              </template>
              <template v-else>
                {{ data.item.gap ? millisecondsToTime(data.item.gap) : '' }}
              </template>
            </template>

            <template #table-busy>
              <div class="text-center">
                <b-spinner class="align-middle mr-2" />
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </vue-perfect-scrollbar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCol, BImg, BRow, BSpinner, BTable,
} from 'bootstrap-vue'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'
import TournamentFormat from '@/constants/TournamentFormat'
import { format } from 'date-fns'
import TournamentStatusType from '@/constants/TournamentStatusType'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { millisecondsToTime } from '../../common/date-utils'

export default {
  components: {
    BImg,
    BSpinner,
    BTable,
    BRow,
    BCol,
    BCard,
    VuePerfectScrollbar,
  },
  mixins: [breadcrumbManager],
  props: {},
  data() {
    return {
      sortBy: 'rank',
      isSortDirDesc: false,
    }
  },
  computed: {
    TournamentStatusType() {
      return TournamentStatusType
    },
    tournament() {
      return this.$store.getters['tournament/getTournament']
    },

    standings() {
      if (this.tournament?.settings?.tournament_format === TournamentFormat.RACE) {
        let results = this.tournament?.matches[0]?.results.slice(0)

        results = results.sort((a, b) => {
          if (a.elapsed_time === null || a.elapsed_time === 0) {
            return 1
          }

          if (b.elapsed_time === null || b.elapsed_time === 0) {
            return -1
          }

          return a.elapsed_time - b.elapsed_time
        })

        return results.map((result, index, array) => ({
          ...result,
          difference: result.elapsed_time && array[index - 1]?.elapsed_time ? result.elapsed_time - array[index - 1].elapsed_time : 0,
          gap: result.elapsed_time && array[index - 1]?.elapsed_time ? result.elapsed_time - array[0].elapsed_time : 0,
        })) || []
      }

      return this.tournament?.results || []
    },

    standingsTableCols() {
      const cols = [
        {
          key: 'rank',
          label: '#',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Participant',
          sortable: false,
          thStyle: { width: '30%' },
        },
      ]

      if (this.tournament?.settings?.tournament_format === TournamentFormat.RACE) {
        cols.push({
          key: 'elapsed_time',
          label: 'Time',
          sortable: false,
        })

        cols.push({
          key: 'difference',
          label: 'Difference',
          sortable: false,
        })

        cols.push({
          key: 'gap',
          label: 'Gap',
          sortable: false,
        })
      } else {
        cols.push({
          key: 'points',
          label: 'Points',
          sortable: true,
        })
      }

      return cols
    },
  },
  async created() {
    await this.$store.dispatch('tournament/fetchTournament', this.$route.params.id)

    await this.hydrateBreadcrumbs()
  },
  methods: {
    millisecondsToTime,
    format,
    async hydrateBreadcrumbs() {
      const data = await this.$store.getters['tournament/getTournament']
      if (data != null) {
        this.replaceBreadcrumb({
          find: 'tournamentName',
          replace: {
            text: data?.name,
            loading: false,
          },
        })
      }
    },
    participantName(standing) {
      if (standing.participant.name) {
        return standing.participant.name
      }
      return standing.participant?.participant?.name
    },
  },
}
</script>

<style lang="scss" scoped>
.standings-view-container {
  height: inherit;
}

.scroll-container {
  height: calc(100% - 88px);
}

.scroll-container.final-results {
  height: calc(100% - 275px);
}

@media (max-width: 767px) {
  .standings-view-container {
    overflow-y: auto;
    height: calc(100% - 47px); // 47px height of footer
    overflow-x: hidden;
  }

  .scroll-container.final-results {
    height: 100%;
  }
}

</style>
